import { Box, Button, HStack, Text, VStack, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TbPlayerTrackNextFilled } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  // fetchHighCourtJudgements,
  fetchSupremeCourtJudgements,
} from "../../../../redux/features/judgementsSlice";

// FIXED HC Judgements As HC API Not working
const HC = {
  Rajasthan: [
    {
      title:
        "Himalaya Global Holdings Ltd & Anr. vs Rajasthan Aushdhalaya Private Limited ...",
      date: "13 March, 2025",
      id: "54510839",
    },
    {
      title: "Tm 25 Holding Bv vs Akhtar Ali & Ors.",
      date: "13 March, 2025",
      id: "64906398",
    },
    {
      title: "Naval Kishore Kapoor vs National Investigation Agency",
      date: "12 March, 2025",
      id: "65320202",
    },
    {
      title: "Monu @ Sandeep vs Union Of India Through Its Secretary & ...",
      date: "12 March, 2025",
      id: "132405804",
    },
    {
      title:
        "Mercedes-Benz Group Ag (Previously ... vs Minda Corporation Limited",
      date: "12 March, 2025",
      id: "133845374",
    },
    {
      title: "Janki Newsprint Ltd vs Principal Commissioner Of Customs New ...",
      date: "12 March, 2025",
      id: "64027764",
    },
    {
      title:
        "M/S Pc Jain Textile Pvt Ltd Through Its ... vs Sh. Shyam Sunder Suri And Anr",
      date: "12 March, 2025",
      id: "19861497",
    },
    {
      title: "Sushil Kumar Alias Raju vs State",
      date: "12 March, 2025",
      id: "87831079",
    },
    {
      title: "M/S B Braun Medical India Pvt Ltd vs Union Of India & Ors.",
      date: "12 March, 2025",
      id: "129760565",
    },
    {
      title: "Shish Ram & Anr vs Govind Vashish",
      date: "12 March, 2025",
      id: "9719096",
    },
    {
      title: "University Of Raj vs State Of Raj And Ors",
      date: "21 December, 2017",
      id: "172207914",
    },
    {
      title: "M B Jhunjhunu vs Prahlad Ram",
      date: "21 December, 2017",
      id: "124160055",
    },
    {
      title: "Dr Rakesh Kumar Meena &Ors; vs State Of Raj And Ors",
      date: "21 December, 2017",
      id: "157100569",
    },
    {
      title: "Ladu Ram vs State & Ors",
      date: "21 December, 2017",
      id: "65680673",
    },
    {
      title: "State Of Raj vs Dr B D Rawat &Ors;",
      date: "21 December, 2017",
      id: "95357356",
    },
    {
      title: "Dr D C Dudi And Ors vs State Of Raj And Ors",
      date: "21 December, 2017",
      id: "27326907",
    },
    {
      title: "University Of Raj vs State Of Raj And Anr",
      date: "21 December, 2017",
      id: "4498881",
    },
    {
      title: "State Of Raj vs Ashok Kumar Yadav&Ors;",
      date: "21 December, 2017",
      id: "11678895",
    },
    {
      title: "Dr Phool Chand Kuldeep vs State Of Raj And Ors",
      date: "21 December, 2017",
      id: "196423317",
    },
    {
      title: "University Of Raj vs State Of Raj & Anr",
      date: "21 December, 2017",
      id: "157142478",
    },

    {
      title: "Vinod Kumar vs State",
      date: "20 December, 2019",
      id: "160096304",
    },
    {
      title: "Shyam Sunder @ Sundariya vs State",
      date: "20 December, 2019",
      id: "86176335",
    },
    {
      title: "Ram Chandra vs Smt. Sirdari And Ors",
      date: "20 December, 2019",
      id: "129997913",
    },
    {
      title: "Prahlad Singh S/O Jagdish Singh vs State Of Rajasthan",
      date: "20 December, 2019",
      id: "185379499",
    },
    {
      title: "Vikram Singh S/O Shri Hazari Lal vs The State Of Rajasthan",
      date: "20 December, 2019",
      id: "169011204",
    },
    {
      title: "Nilesh Kumar Jain And Ors vs State Of Raj And Ors",
      date: "20 December, 2019",
      id: "179240059",
    },
    {
      title: "Dhara Singh S/O Shri Jagdish Singh B/C ... vs State Of Rajasthan",
      date: "20 December, 2019",
      id: "95348020",
    },
    {
      title: "Krishna Kumar Meena S/O Shri Jagdish ... vs State Of Rajasthan",
      date: "20 December, 2019",
      id: "42947500",
    },
    {
      title:
        "M/S Ritika Vegetable Oil Private ... vs M/S Deepak Vegpro Private Limited",
      date: "19 December, 2019",
      id: "31304690",
    },
    {
      title: "The State Of Rajasthan vs Subhash Chandra S/O Shri Lal Chand",
      date: "19 December, 2019",
      id: "115283380",
    },
    {
      title: "Rajasthan Prashasanik Sewa Parishad vs Union Of India",
      date: "24 December, 2020",
      id: "190068852",
    },
    {
      title:
        "Sunil Samdaria Son Of Late Sh. B. L. ... vs The State Government Of Rajasthan",
      date: "18 December, 2020",
      id: "96552152",
    },
    {
      title: "Anil Dutt Joshi vs N.C.B",
      date: "18 December, 2020",
      id: "1792409",
    },
    {
      title: "Vikram Pratap Singh @ Monu vs State",
      date: "18 December, 2020",
      id: "187316411",
    },
    {
      title: "Satish Sarda vs State Of Rajasthan",
      date: "18 December, 2020",
      id: "156629660",
    },
    {
      title: "Smt. Sona Yadav W/O Shriram Yadav D/O ... vs State Of Rajasthan",
      date: "18 December, 2020",
      id: "967905",
    },
    {
      title: "Ram Varan S/O Daulat Ram vs State Of Rajasthan",
      date: "17 December, 2020",
      id: "176226735",
    },
    {
      title:
        "Rajesh Kumar Peeploda S/O Shri Ram Sahay ... vs State Of Rajasthan",
      date: "17 December, 2020",
      id: "13775618",
    },
    {
      title: "Om Prakash Jat, Son Of Shri Devi Sahai Jat vs State Of Rajasthan",
      date: "17 December, 2020",
      id: "143531216",
    },
    {
      title: "Shri Nivash Verma vs State Of Rajasthan",
      date: "17 December, 2020",
      id: "154242080",
    },

    {
      title: "Ashok Kumar Sharma S/O Late Shri ... vs State Of Rajasthan",
      date: "27 December, 2021",
      id: "10821491",
    },
    {
      title: "Om Prakash And Anr vs State",
      date: "21 December, 2021",
      id: "48407776",
    },
    {
      title:
        "Vaibhav Gehlot S/O Shri Ashok Gehlot vs Joint Commissioner Of Income Tax",
      date: "21 December, 2021",
      id: "14142055",
    },
    {
      title: "Sopa Ram vs Gamna Ram",
      date: "21 December, 2021",
      id: "146306427",
    },
    {
      title: "Manish Bansal S/O Sh. Nawal Kishor ... vs Income Tax Officer",
      date: "21 December, 2021",
      id: "135008913",
    },
    {
      title: "Jaipur Build Developers Private ... vs Income Tax Officer",
      date: "21 December, 2021",
      id: "188124206",
    },
    {
      title:
        "Aaditya Finechem Pvt. Ltd vs Principal Commissioner Of Income Tax",
      date: "21 December, 2021",
      id: "42525610",
    },
    {
      title:
        "Smt. Sultana Begum W/O Shri Haji Haider ... vs Smt. Sardara Devi W/O Late Shri Gappu Lal ...",
      date: "21 December, 2021",
      id: "9697326",
    },
    {
      title: "Nanu Ram Meena S/O Ram Narayan Meena vs Income Tax Officer",
      date: "21 December, 2021",
      id: "135598104",
    },
    {
      title: "Poonam vs State Medical And Health Ors",
      date: "21 December, 2021",
      id: "8787977",
    },

    {
      title: "Smt Indra Devi vs Ragjav Dass Goyal And Another",
      date: "22 December, 2022",
      id: "149202032",
    },
    {
      title:
        "Surendra Kanwar Shekhawat vs Authorized Officer, Punjab National ...",
      date: "22 December, 2022",
      id: "162076663",
    },
    {
      title: "Mahesh Nagar vs Uoi And Ors",
      date: "22 December, 2022",
      id: "95393429",
    },
    {
      title: "M/S Sky Light Hospitality Llp vs The Union Of India",
      date: "22 December, 2022",
      id: "44110630",
    },
    {
      title: "Uchhab Kanwar vs Authorized Officer",
      date: "22 December, 2022",
      id: "169139400",
    },
    {
      title: "Giriraj Gujrati vs State Of Rajasthan",
      date: "22 December, 2022",
      id: "146278102",
    },
    {
      title: "Raj Kumar vs Lrs Of Duda",
      date: "22 December, 2022",
      id: "97089347",
    },
    {
      title: "Ramji Das vs State Of Rajasthan",
      date: "22 December, 2022",
      id: "55306188",
    },
    {
      title: "Pradeep Kumar Khanagwal S/O Jugel ... vs The State Of Rajasthan",
      date: "22 December, 2022",
      id: "183865001",
    },
    {
      title: "Magaram vs State Of Rajasthan",
      date: "22 December, 2022",
      id: "151241771",
    },
    {
      title: "Rama Asnani vs State Of Rajasthan",
      date: "23 December, 2023",
      id: "17751525",
    },
    {
      title: "Ranjeet Singh And Ors vs Manish Kumar And Ors",
      date: "22 December, 2023",
      id: "53172258",
    },
    {
      title: "Smt. Ugam Kanwar And Anr vs Manish Kumar And Ors",
      date: "22 December, 2023",
      id: "113456174",
    },
    {
      title: "Smt. Kanku Kunwar And Ors vs Manish Kumar And Ors",
      date: "22 December, 2023",
      id: "144735600",
    },
    {
      title: "Shyamsundar Tak vs Neeraj Chandak (2023:Rj-Jd:44998)",
      date: "22 December, 2023",
      id: "38243814",
    },
    {
      title: "Prahlad Ram vs State Of Rajasthan (2023:Rj-Jd:45019)",
      date: "22 December, 2023",
      id: "51343446",
    },
    {
      title: "Union Of India And Ors vs Brij Lal And Anr",
      date: "22 December, 2023",
      id: "59428107",
    },
    {
      title: "Jojo Abraham vs Bhagwatilal And Ors",
      date: "22 December, 2023",
      id: "83638447",
    },
    {
      title: "M.C.B. Ashram Through F.R. Superior vs Bhagwati Lal And Ors",
      date: "22 December, 2023",
      id: "61603395",
    },
    {
      title: "Manfool vs State Of Rajasthan (2023:Rj-Jd:45021)",
      date: "22 December, 2023",
      id: "140646217",
    },

    {
      title: "Suo Motu - In The Matter Of Massive Fire ... vs Union Of India",
      date: "21 December, 2024",
      id: "123153374",
    },
    {
      title: "Geetanjali Vajpayee Daughter Of ... vs The State Of Rajasthan",
      date: "20 December, 2024",
      id: "170964645",
    },
    {
      title:
        "Narpat Surela Son Of Kishori Lal Surela vs The State Of Rajasthan",
      date: "20 December, 2024",
      id: "34668352",
    },
    {
      title:
        "Dheeraj Khandelwal S/O Shri Devendra ... vs Central Bureau Of Narcotics ...",
      date: "20 December, 2024",
      id: "40531064",
    },
    {
      title:
        "M/S. Hasti Petro Chemical And Shipp. And ... vs Union Of India And Ors",
      date: "20 December, 2024",
      id: "170605122",
    },
    {
      title: "Lrs Of Chuki Devi And Ors vs State And Ors",
      date: "20 December, 2024",
      id: "24710959",
    },
    {
      title: "M/S. Hasti Petro Chemical And Shipping ... vs Union Of India",
      date: "20 December, 2024",
      id: "175121989",
    },
    {
      title: "Vishnu Dutt vs State And Ors",
      date: "20 December, 2024",
      id: "76002128",
    },
    {
      title: "Basanti Devi vs State And Ors",
      date: "20 December, 2024",
      id: "70106586",
    },
    {
      title: "C.I.T. Udaipur vs M/S Hindustan Zinc Ltd",
      date: "20 December, 2024",
      id: "83194890",
    },
  ],

    Delhi: [
    {
      "title": "Prashant Pranav & Ors. vs Union Of India & Ors.",
      "date": "26 December, 2017",
      "id": "72600917"
  },
  {
      "title": "Mukesh @ Mukku vs State",
      "date": "23 December, 2017",
      "id": "19141165"
  },
  {
      "title": "Daljeet Yadav vs The State & Anr",
      "date": "23 December, 2017",
      "id": "34973815"
  },
  {
      "title": "Joginder Singh Dhaiya vs M.A Tarde Thr Lrs",
      "date": "22 December, 2017",
      "id": "49068842"
  },
  {
      "title": "K.S. Bhandari vs International Security Printers Pvt ...",
      "date": "22 December, 2017",
      "id": "67209390"
  },
  {
      "title": "Pasithea Infrastructure Limited vs Solar Energy Corporation Of India & Anr.",
      "date": "22 December, 2017",
      "id": "90140896"
  },
  {
      "title": "Maruti Suzuki India Ltd. vs Delhi Auto General Finance Pvt. Ltd.",
      "date": "22 December, 2017",
      "id": "174835241"
  },
  {
      "title": "Godfrey Phillips India Limited vs P.T.I Private Limited & Ors.",
      "date": "22 December, 2017",
      "id": "176851571"
  },
  {
      "title": "Ivrcl Ltd. vs Rail Vikash Nigam Limited & Ors",
      "date": "22 December, 2017",
      "id": "131326253"
  },
  {
      "title": "Sintex Oil And Gas Limited vs Union Of India & Ors.",
      "date": "22 December, 2017",
      "id": "54749570"
  },
  {
    "title": "Amit Kumar vs Kanika Hajika",
    "date": "31 December, 2018",
    "id": "106752782"
},
{
    "title": "Kuldeep Kumar vs Union Of India And Ors.",
    "date": "30 December, 2018",
    "id": "14773919"
},
{
    "title": "Bharat Heavy Electricals Ltd vs Udaibir Singh & Ors.",
    "date": "29 December, 2018",
    "id": "4000086"
},
{
    "title": "Subhash Chander Rana vs Jitender Verma",
    "date": "29 December, 2018",
    "id": "148062273"
},
{
    "title": "N K Rai vs Central Board Of Investigation",
    "date": "29 December, 2018",
    "id": "56773832"
},
{
    "title": "Abhinav Singh & Ors. vs Jyoti, Inspector & Ors.",
    "date": "26 December, 2018",
    "id": "22455923"
},
{
    "title": "B S Tolani vs Govt. Of Nct Of Delhi & Ors.",
    "date": "21 December, 2018",
    "id": "77112231"
},
{
    "title": "Ge Energy Parts Inc. vs Cit (International Taxation), Delhi-I",
    "date": "21 December, 2018",
    "id": "8820457"
},
{
    "title": "Vinayaga Marine Petro Ltd. vs Union Of India & Ors.",
    "date": "21 December, 2018",
    "id": "55756376"
},
{
    "title": "Poonnamma Vishwanathan vs M/S Moolchand Khairati Ram Hospital & ...",
    "date": "21 December, 2018",
    "id": "64377847"
},
{
  "title": "P.L Bhasin & Ors vs North Delhi Municipal Corporation & Ors",
  "date": "30 December, 2019",
  "id": "136631398"
},
{
  "title": "Sayed Ahmed vs Dri",
  "date": "27 December, 2019",
  "id": "153242743"
},
{
  "title": "Divij And Ors. vs Guru Gobind Singh Indraprastha ...",
  "date": "27 December, 2019",
  "id": "38403333"
},
{
  "title": "Directorate Of Revenue Intelligence vs Mohammed Nashruddin",
  "date": "27 December, 2019",
  "id": "158396711"
},
{
  "title": "Amit Kumar vs Union Of India",
  "date": "27 December, 2019",
  "id": "83319229"
},
{
  "title": "Puran Lal @ Bhaskar vs State",
  "date": "24 December, 2019",
  "id": "182609690"
},
{
  "title": "Suraj Puri vs State",
  "date": "24 December, 2019",
  "id": "140412497"
},
{
  "title": "State vs Sunil Kumar",
  "date": "24 December, 2019",
  "id": "71566576"
},
{
  "title": "Zeeny Jhelumi vs Inderpreet Singh Jhelumi",
  "date": "24 December, 2019",
  "id": "107295889"
},
{
  "title": "National Highways Authority Of India vs Patel Knr (Jv)",
  "date": "24 December, 2019",
  "id": "34759013"
},
{
  "title": "Isgec Heavy Engineering Ltd vs Indian Oil Corporation Ltd & Anr.",
  "date": "31 December, 2020",
  "id": "70092062"
},
{
  "title": "Radhika Byrn vs Union Of India & Anr.",
  "date": "28 December, 2020",
  "id": "118526181"
},
{
  "title": "Dabur India Limited vs Marico Limited",
  "date": "28 December, 2020",
  "id": "67666016"
},
{
  "title": "Wg Cdr Vidhu Singh (29399-H) Ae(L), vs Union Of India, & Ors.",
  "date": "28 December, 2020",
  "id": "150194787"
},
{
  "title": "Cdr Amit Khajuria vs Union Of India And Ors",
  "date": "28 December, 2020",
  "id": "22957621"
},
{
  "title": "Icici Bank vs Jain Logistics & Ors.",
  "date": "28 December, 2020",
  "id": "113483940"
},
{
  "title": "Phelan Energy India R J Pvt Ltd vs Indusind Bank & Anr.",
  "date": "28 December, 2020",
  "id": "158179723"
},
{
  "title": "Indian Agro & Recycled Paper Mills ... vs Tafcon Projects(India) Pvt Ltd ...",
  "date": "24 December, 2020",
  "id": "153195390"
},
{
  "title": "Sonu @ Hemraj vs State (Nct Of Delhi)",
  "date": "24 December, 2020",
  "id": "91467019"
},
{
  "title": "Jamila vs Union Of India Through Ministry Of ...",
  "date": "24 December, 2020",
  "id": "46267833"
}, {
  "title": "Pratibha Gaur vs Government Of Nct Of Delhi & Ors.",
  "date": "31 December, 2021",
  "id": "179613833"
},
{
  "title": "Yogender Chandolia vs Vishesh Ravi & Ors.",
  "date": "24 December, 2021",
  "id": "34039512"
},
{
  "title": "M/S Tarun Aggarwal Projects Llp & Anr. vs M/S Emaar Mgf Land Ltd.",
  "date": "24 December, 2021",
  "id": "120702260"
},
{
  "title": "North Delhi Municipal Corporation vs Bal Kishan & Anr.",
  "date": "24 December, 2021",
  "id": "16575538"
},
{
  "title": "Dinesh Gupta And Ors. vs Bechu Singh And Anr.",
  "date": "24 December, 2021",
  "id": "127827186"
},
{
  "title": "Sandeep Aggarwal vs Priyanka Agarwal",
  "date": "24 December, 2021",
  "id": "6051861"
},
{
  "title": "Yashpal Singh vs State",
  "date": "24 December, 2021",
  "id": "109915833"
},
{
  "title": "Satluj Jal Vidyut Nigam Ltd vs Jaiprakash Hyundai Consortium (Jhc)",
  "date": "24 December, 2021",
  "id": "1435985"
},
{
  "title": "Balwinder Singh vs Kamal Kishore",
  "date": "24 December, 2021",
  "id": "72871752"
},
{
  "title": "M/S Essjay Ericsson Private Limited vs Commissioner Of Income Tax, New Delhi & ...",
  "date": "24 December, 2021",
  "id": "138205458"
},
{
  "title": "Ashwani Kumar vs State",
  "date": "30 December, 2022",
  "id": "46680097"
},
{
  "title": "Mohd Tauseef vs State Govt. Of Nct Of Delhi",
  "date": "30 December, 2022",
  "id": "180112380"
},
{
  "title": "Akshay Dhingra vs State (Govt. Of Nct Of Delhi)",
  "date": "30 December, 2022",
  "id": "48924143"
},
{
  "title": "Suresh Chikara vs Govt Of Nct Of Delhi Through Its Chief ...",
  "date": "30 December, 2022",
  "id": "41611288"
},
{
  "title": "Tika Ram Khare vs State Of Nct Of Delhi",
  "date": "30 December, 2022",
  "id": "84325018"
},
{
  "title": "Tika Ram Khare vs State Of Nct Delhi",
  "date": "30 December, 2022",
  "id": "152047104"
},
{
  "title": "Imran @ Sonu vs The State",
  "date": "28 December, 2022",
  "id": "195334786"
},
{
  "title": "Mumtaz vs State(Nct Of Delhi) & Anr.",
  "date": "28 December, 2022",
  "id": "59253632"
},
{
  "title": "Dbm College Of Pharmacy vs Pharmacy Council Of India",
  "date": "28 December, 2022",
  "id": "118941718"
},
{
  "title": "Tommorrowland Limited vs V Jethalal Ramji & Ors",
  "date": "27 December, 2022",
  "id": "140473119"
}, {
            "title": "Ashwani Kumar vs State",
            "date": "30 December, 2022",
            "id": "46680097"
        },
        {
            "title": "Mohd Tauseef vs State Govt. Of Nct Of Delhi",
            "date": "30 December, 2022",
            "id": "180112380"
        },
        {
            "title": "Akshay Dhingra vs State (Govt. Of Nct Of Delhi)",
            "date": "30 December, 2022",
            "id": "48924143"
        },
        {
            "title": "Suresh Chikara vs Govt Of Nct Of Delhi Through Its Chief ...",
            "date": "30 December, 2022",
            "id": "41611288"
        },
        {
            "title": "Tika Ram Khare vs State Of Nct Of Delhi",
            "date": "30 December, 2022",
            "id": "84325018"
        },
        {
            "title": "Tika Ram Khare vs State Of Nct Delhi",
            "date": "30 December, 2022",
            "id": "152047104"
        },
        {
            "title": "Imran @ Sonu vs The State",
            "date": "28 December, 2022",
            "id": "195334786"
        },
        {
            "title": "Mumtaz vs State(Nct Of Delhi) & Anr.",
            "date": "28 December, 2022",
            "id": "59253632"
        },
        {
            "title": "Dbm College Of Pharmacy vs Pharmacy Council Of India",
            "date": "28 December, 2022",
            "id": "118941718"
        },
        {
            "title": "Tommorrowland Limited vs V Jethalal Ramji & Ors",
            "date": "27 December, 2022",
            "id": "140473119"
        }, {
          "title": "Khadi And Village Industries ... vs Girdhar Industries And Anr.",
          "date": "28 December, 2023",
          "id": "148686013"
      },
      {
          "title": "Kemwell Biopharma Pvt. Ltd & Anr. vs Uoi & Anr.",
          "date": "26 December, 2023",
          "id": "16241850"
      },
      {
          "title": "Van Tibolli & Anr. vs K. Srinivas Rao & Anr.",
          "date": "26 December, 2023",
          "id": "125149607"
      },
      {
          "title": "Amritesh Jatia vs Vidhi Binani Jatia",
          "date": "26 December, 2023",
          "id": "11111449"
      },
      {
          "title": "Glaxo Smithkline Pharma Ltd. & vs Uoi & Anr.",
          "date": "26 December, 2023",
          "id": "148489523"
      },
      {
          "title": "M/ S. Adani Agri Logistics Ltd. vs Food Corporation Of India",
          "date": "22 December, 2023",
          "id": "95019032"
      },
      {
          "title": "Ms Renu Bala & Ors. vs Union Of India & Ors.",
          "date": "22 December, 2023",
          "id": "58338903"
      },
      {
          "title": "Hyatt International Southwest Asia ... vs Acit(International ...",
          "date": "22 December, 2023",
          "id": "71819731"
      },
      {
          "title": "Hyatt International Southwest Asia Ltd vs Additional Director Of Income Tax",
          "date": "22 December, 2023",
          "id": "164637258"
      },
      {
          "title": "Devender Dubey vs State Of N.C.T. Of Delhi.",
          "date": "22 December, 2023",
          "id": "86434040"
      }, {
        "title": "Abhinav Singh Ravish & Anr. vs Shri Ramanand & Ors.",
        "date": "30 December, 2024",
        "id": "64709398"
    },
    {
        "title": "M/S. Mgm Papers Llp vs M S Samman Lal Sher Singh Paper Pvt Ltd And ...",
        "date": "30 December, 2024",
        "id": "142748779"
    },
    {
        "title": "Pawan Gupta Anr vs Miton Credentia Trusteeship Services ...",
        "date": "27 December, 2024",
        "id": "4361712"
    },
    {
        "title": "M/S Evinix Designs Concepts P Ltd. vs Lt. Governor Nct Of Delhi & Anr",
        "date": "24 December, 2024",
        "id": "33392616"
    },
    {
        "title": "Kailash Aggarwal And Anr. vs Ms. Chandan Ramamurthi And Anr.",
        "date": "24 December, 2024",
        "id": "196908768"
    },
    {
        "title": "Puneet Kumar Anand vs Pishori Lal (Deceased) Thr Lrs",
        "date": "24 December, 2024",
        "id": "114736868"
    },
    {
        "title": "Chandan Ramamurthi & Anr vs M.R Krishnamurthi & Anr",
        "date": "24 December, 2024",
        "id": "58823351"
    },
    {
        "title": "Arun Nanda vs State & Anr.",
        "date": "24 December, 2024",
        "id": "9315857"
    },
    {
        "title": "Smt. Kamlesh vs Shri Tejpal",
        "date": "24 December, 2024",
        "id": "191194918"
    },
    {
        "title": "Mario Anthony Nazareth vs State & Anr.",
        "date": "24 December, 2024",
        "id": "110448372"
    }, {
            "title": "Abhinav Singh Ravish & Anr. vs Shri Ramanand & Ors.",
            "date": "30 December, 2024",
            "id": "64709398"
        },
        {
            "title": "M/S. Mgm Papers Llp vs M S Samman Lal Sher Singh Paper Pvt Ltd And ...",
            "date": "30 December, 2024",
            "id": "142748779"
        },
        {
            "title": "Pawan Gupta Anr vs Miton Credentia Trusteeship Services ...",
            "date": "27 December, 2024",
            "id": "4361712"
        },
        {
            "title": "M/S Evinix Designs Concepts P Ltd. vs Lt. Governor Nct Of Delhi & Anr",
            "date": "24 December, 2024",
            "id": "33392616"
        },
        {
            "title": "Kailash Aggarwal And Anr. vs Ms. Chandan Ramamurthi And Anr.",
            "date": "24 December, 2024",
            "id": "196908768"
        },
        {
            "title": "Puneet Kumar Anand vs Pishori Lal (Deceased) Thr Lrs",
            "date": "24 December, 2024",
            "id": "114736868"
        },
        {
            "title": "Chandan Ramamurthi & Anr vs M.R Krishnamurthi & Anr",
            "date": "24 December, 2024",
            "id": "58823351"
        },
        {
            "title": "Arun Nanda vs State & Anr.",
            "date": "24 December, 2024",
            "id": "9315857"
        },
        {
            "title": "Smt. Kamlesh vs Shri Tejpal",
            "date": "24 December, 2024",
            "id": "191194918"
        },
        {
            "title": "Mario Anthony Nazareth vs State & Anr.",
            "date": "24 December, 2024",
            "id": "110448372"
        },
        {
          "title": "Sandeep Kumar Bhatt vs Insolvency And Bankruptcy Board Of ...",
          "date": "3 April, 2025",
          "id": "196290495"
      },
      {
          "title": "Smt. Manju & Ors. vs Mahesh Kumar (Driver) & Anr.",
          "date": "3 April, 2025",
          "id": "71574063"
      },
      {
          "title": "Gurmeet Singh vs Delhi Metro Rail Corporation Ltd",
          "date": "3 April, 2025",
          "id": "100727019"
      },
      {
          "title": "Aarti Mathur vs Union Of India Through Director General ...",
          "date": "3 April, 2025",
          "id": "154806428"
      },
      {
          "title": "Ajay Gautam vs Union Of India Through Ministry Of Law ...",
          "date": "3 April, 2025",
          "id": "132378259"
      },
      {
          "title": "Mayank Pandey vs State (Nct Of Delhi)",
          "date": "3 April, 2025",
          "id": "178054503"
      },
      {
          "title": "Mohd Azim Nadaf vs State Govt. Of Nct Of Delhi",
          "date": "3 April, 2025",
          "id": "118972797"
      },
      {
          "title": "S.Sunil Kumar vs Union Of India And Ors",
          "date": "3 April, 2025",
          "id": "179213570"
      },
      {
          "title": "Ashwani Kumar @ Ashu vs State Govt. Of Nct Of Delhi",
          "date": "3 April, 2025",
          "id": "61862733"
      },
      {
          "title": "Deepak Kumar Singh vs Union Of India & Ors.",
          "date": "2 April, 2025",
          "id": "48012178"
      }
    ],

      Madras: [
        {
            "title": "M.S.R.T.C. Dhule Thr Its Div. ... vs Santosh Devidas Dhondde And Anr",
            "date": "3 April, 2025",
            "id": "174001885"
        },
        {
            "title": "State Of U.P. vs Harveer Yadav S/O Prem Singh @Mooseram",
            "date": "3 April, 2025",
            "id": "171024737"
        },
        {
            "title": "Sundar Singh vs State Of U.P. And Another",
            "date": "3 April, 2025",
            "id": "12292815"
        },
        {
            "title": "WP(C)/920/2025",
            "date": "3 April, 2025",
            "id": "33545400"
        },
        {
            "title": "Hari Narain Pal vs Addl. District Judge Court No. 4 Special ...",
            "date": "3 April, 2025",
            "id": "89153940"
        },
        {
            "title": "P V Mudhun Reddy Peddireddi Venkata ... vs The State Of Andhra Pradesh",
            "date": "3 April, 2025",
            "id": "107524831"
        },
        {
            "title": "Punabhai Bhanabhai Bharvad Through ... vs District Magistrate",
            "date": "3 April, 2025",
            "id": "123215565"
        },
        {
            "title": "Jignashaben W/O Batukbhai Sonpal ... vs State Of Gujarat",
            "date": "3 April, 2025",
            "id": "121806391"
        },
        {
            "title": "Sanjay @ Sanju S/O Anilbhai Vadher ... vs State Of Gujarat",
            "date": "3 April, 2025",
            "id": "83080476"
        },
        {
            "title": "Syed Humayuna Pasha vs Mohammed Shah Fahad",
            "date": "3 April, 2025",
            "id": "94660927"
        },
        {
          "title": "Poonam Tyagi,Ghaziabad vs Dcit, Central Circle, Ghaziabad",
          "date": "31 December, 2024",
          "id": "158279181"
      },
      {
          "title": "Poonam Tyagi,Ghaziabad vs Dcit, Central Circle, Ghazaibad",
          "date": "31 December, 2024",
          "id": "107476174"
      },
      {
          "title": "Discovery Networks Asia Pacific Pte ... vs Acit, Circle- 1(2)(2), International ...",
          "date": "31 December, 2024",
          "id": "42377442"
      },
      {
          "title": "Pb Fintech Limited,Gurgaon vs Acit,Circle-1(1), Gurgaon",
          "date": "31 December, 2024",
          "id": "116932692"
      },
      {
          "title": "Acit, New Delhi vs M/S. Zoom Communication Ltd., New Delhi",
          "date": "31 December, 2024",
          "id": "101887091"
      },
      {
          "title": "Reserved On : 24.12.2024 vs State Of Himachal Pradesh & Ors",
          "date": "31 December, 2024",
          "id": "132417183"
      },
      {
          "title": "Dcit, Central Circle-20, Delhi vs Rajiv Agarwal, Delhi",
          "date": "31 December, 2024",
          "id": "146428944"
      },
      {
          "title": "Acit, Circle- 1(2)(2), International ... vs Discovery Networks Asia Pacific Pte ...",
          "date": "31 December, 2024",
          "id": "102106055"
      },
      {
          "title": "Rajiv Agarwal,New Delhi vs Acit Central Circle-20, New Delhi",
          "date": "31 December, 2024",
          "id": "16107483"
      },
      {
          "title": "Ritu Tuli,Gurgaon vs Dcit, Central Circle-8, New Delhi",
          "date": "31 December, 2024",
          "id": "110700984"
      },
      {
        "title": "Jang Bhadur & Anr vs Muslim Auqaf Jammu &Ors",
        "date": "30 December, 2023",
        "id": "146695343"
    },
    {
        "title": "Imtiyaz Ahmed Matoo vs Ghulam Rasool Sofi",
        "date": "30 December, 2023",
        "id": "10400593"
    },
    {
        "title": "Pritam Chand Alias Pritam Singh vs Dr. Kamal Saini",
        "date": "30 December, 2023",
        "id": "136731913"
    },
    {
        "title": "Mehrun Nisa D/O Ab. Rehman Bhat vs Sheri Kashmir Institute Of Medical ...",
        "date": "30 December, 2023",
        "id": "40647004"
    },
    {
        "title": "Juniper Networks India P. Ltd, Mumbai vs Dy Cit-Cc-2(4), Mumbai",
        "date": "30 December, 2023",
        "id": "3604114"
    },
    {
        "title": "Management Of Ambuja Cements vs Industrial Tribunal Cum Labour Court",
        "date": "30 December, 2023",
        "id": "67806075"
    },
    {
        "title": "Mudassir Hussain vs The Union Territory Of Jammu",
        "date": "30 December, 2023",
        "id": "27464855"
    },
    {
        "title": "P. K. Sunil vs Union Of India Through Home",
        "date": "30 December, 2023",
        "id": "190503851"
    },
    {
        "title": "Arshad Hussain vs Ut Of J&K",
        "date": "30 December, 2023",
        "id": "42960234"
    },
    {
        "title": "State Of Jammu And Kashmir Th vs Yogesh Kumar Alias Jyoti",
        "date": "30 December, 2023",
        "id": "34650717"
    },
    {
      "title": "The State Of Karnataka vs No.1 To 3:- 1. Bharath. R. S/O. V. Ramu",
      "date": "31 December, 2022",
      "id": "100418083"
  },
  {
      "title": "Union Of India & Anr vs Buddha Tri-Ratna Mission & Anr",
      "date": "31 December, 2022",
      "id": "28988742"
  },
  {
      "title": "State By - Airport Police Station vs Jafrulla Khan",
      "date": "31 December, 2022",
      "id": "188024638"
  },
  {
      "title": "M/S.Laxmi Inputs vs Yogendra Katiyar",
      "date": "31 December, 2022",
      "id": "147791198"
  },
  {
      "title": ") The Glamour Inc vs Mr. Prema Kumar Burle",
      "date": "31 December, 2022",
      "id": "105146822"
  },
  {
      "title": "Jayadev.K.N vs M/S Maruthi Merchants Finance Company",
      "date": "31 December, 2022",
      "id": "117227861"
  },
  {
      "title": "Ph. No.95351 13837 vs R/At No.68",
      "date": "31 December, 2022",
      "id": "71511890"
  },
  {
      "title": "State By vs Chandrashekar S/O M Shamanna",
      "date": "31 December, 2022",
      "id": "173035356"
  },
  {
      "title": "Pardeep Kumar vs State Of H.P. And Another",
      "date": "30 December, 2022",
      "id": "62719001"
  },
  {
      "title": "Preeti Gupta And 4 Others vs Alcott Town Planners Private Limited ...",
      "date": "30 December, 2022",
      "id": "154765388"
  },
  {
    "title": "Pawan Kumar S/O Sh. Rattan vs State Of H.P And Others)",
    "date": "31 December, 2021",
    "id": "76995095"
},
{
    "title": "Pawan Kumar S/O Sh. Rattan vs State Of H.P And Others)",
    "date": "31 December, 2021",
    "id": "116387941"
},
{
    "title": "Pawan Kumar S/O Sh. Rattan vs State Of H.P And Others)",
    "date": "31 December, 2021",
    "id": "116351008"
},
{
    "title": "Pawan Kumar S/O Sh. Rattan vs State Of H.P And Others)",
    "date": "31 December, 2021",
    "id": "173867022"
},
{
    "title": "Central Bureau Of Investigation Anti ... vs Sumeet Kumar & Anr",
    "date": "31 December, 2021",
    "id": "47090604"
},
{
    "title": "(Represented By The Learned Senior App) vs No.4 Has Been Reported Dead",
    "date": "31 December, 2021",
    "id": "55324292"
},
{
    "title": "Pawan Kumar S/O Sh. Rattan vs State Of H.P And Others)",
    "date": "31 December, 2021",
    "id": "31686800"
},
{
    "title": "K K Sherwani vs The A.P State Waqf Board,",
    "date": "31 December, 2021",
    "id": "138232747"
},
{
    "title": "Pawan Kumar S/O Sh. Rattan vs State Of H.P And Others)",
    "date": "31 December, 2021",
    "id": "88807021"
},
{
    "title": "Government Of Andhra Pradesh, Rep. By ... vs Mailarapu Jangaiah",
    "date": "31 December, 2021",
    "id": "154245030"
},
{
  "title": "Veera Abimanyu vs State Rep.By",
  "date": "31 December, 2020",
  "id": "130143405"
},
{
  "title": "Ch. Vinay vs High Court Of Andhra Pradesh,",
  "date": "31 December, 2020",
  "id": "78488512"
},
{
  "title": "Santhanakrishnan vs State",
  "date": "31 December, 2020",
  "id": "179733249"
},
{
  "title": "Arulmani vs State Rep.By",
  "date": "31 December, 2020",
  "id": "129993648"
},
{
  "title": "M/S Madan Lal Wadhwa & Co vs Hindustan Petroleum Corp. Ltd",
  "date": "31 December, 2020",
  "id": "126740909"
},
{
  "title": "Mahesh vs State Rep.By",
  "date": "31 December, 2020",
  "id": "167908155"
},
{
  "title": "Ramesh vs The State Rep By Its",
  "date": "31 December, 2020",
  "id": "13059963"
},
{
  "title": "Gajendran vs State Rep.By",
  "date": "31 December, 2020",
  "id": "35008924"
},
{
  "title": "Poovizhiselvi vs State Rep.By",
  "date": "31 December, 2020",
  "id": "12307709"
},
{
  "title": "Vinoth vs State Rep.By",
  "date": "31 December, 2020",
  "id": "177284502"
},
{
  "title": "State By Inspector Of Police vs Sri M.T.Venkatashiva Reddy S/O",
  "date": "31 December, 2019",
  "id": "140071448"
},
{
  "title": "M/S. Sai Krishna Alloys vs The Superintending Engineer (Metro)",
  "date": "31 December, 2019",
  "id": "11302521"
},
{
  "title": "M/S. Mala Roy & Others, Kolkata vs Dcit, Central Circle - 1(1), Kolkata , ...",
  "date": "31 December, 2019",
  "id": "94280993"
},
{
  "title": "Sri B. Vinod vs The State Of Andhra Pradesh",
  "date": "31 December, 2019",
  "id": "64136569"
},
{
  "title": "The Challenge In This Criminal Revision ... vs Unknown",
  "date": "31 December, 2019",
  "id": "107637462"
},
{
  "title": "Smt D Sailaja, Chittoor Dist vs B Papaiah Naidu, Chittoor Dist",
  "date": "31 December, 2019",
  "id": "95390412"
},
{
  "title": "M/S. Aic Iron Industries Pvt. Ltd., ... vs Pr.Cit-1, Kolkata",
  "date": "31 December, 2019",
  "id": "180369526"
},
{
  "title": "The Principal, Saldiha College, ... vs Ito, Ward - 4(4), Tds, Bankura & Purulia",
  "date": "31 December, 2019",
  "id": "186558902"
},
{
  "title": "A Ashok Kumar vs Bharat Sanchar Nigam Limited",
  "date": "31 December, 2019",
  "id": "129208659"
},
{
  "title": "Oriental Aromatics Ltd, Mumbai vs Asst Cit Cir 1(2)(1), Mumbai",
  "date": "31 December, 2019",
  "id": "12304384"
},
{
  "title": "Gmr Vemagiri Power Gen. Ltd. vs Secy., Aperc 12 Ors.",
  "date": "31 December, 2018",
  "id": "134233756"
},
{
  "title": "Gmr Vemagiri vs Ts.E.R.C And 12 Others",
  "date": "31 December, 2018",
  "id": "55390265"
},
{
  "title": "Jitinder Singh Chadha, New Delhi vs Prcit-18, New Delhi",
  "date": "31 December, 2018",
  "id": "81194072"
},
{
  "title": "V Venkata Ramana vs The State Of A.P.,Rep.,Pp And Another",
  "date": "31 December, 2018",
  "id": "9787447"
},
{
  "title": "Gvk Industries Ltd., Hyd Another vs Secy, Legislative Dept., Ministry Of ...",
  "date": "31 December, 2018",
  "id": "67217770"
},
{
  "title": "Acit, Cc-3(2), Kolkata, Kolkata vs M/S. Chowringhee Residency Pvt. Ltd., ...",
  "date": "31 December, 2018",
  "id": "161912356"
},
{
  "title": "Gmr Vemagiri Power Generation Limited, ... vs Secy, Aperc., Hyd 12 Ot",
  "date": "31 December, 2018",
  "id": "184682338"
},
{
  "title": "Gvk Industries Ltd vs Prl.Secretary Energy And 10 Others",
  "date": "31 December, 2018",
  "id": "55943587"
},
{
  "title": "Prem Singh vs Dharam Singh",
  "date": "31 December, 2018",
  "id": "99906699"
},
{
  "title": "Marakkagari Krishnappa vs The State Of Andhra Pradesh",
  "date": "31 December, 2018",
  "id": "75754599"
},
{
  "title": "Cognizant (Mauritius) Limited vs The Deputy Commissioner Of Income Tax",
  "date": "31 December, 2017",
  "id": "72337599"
},
{
  "title": "The Indian Institutes of Management Act, 2017",
  "id": "126900355"
},
{
  "title": "Sat Paul vs State Of H.P. And Another",
  "date": "30 December, 2017",
  "id": "29293113"
},
{
  "title": "Mohammad Saleem Tantray vs State And Ors",
  "date": "30 December, 2017",
  "id": "173173579"
},
{
  "title": "Bhagwan Dass & Others vs State Of J&K;",
  "date": "30 December, 2017",
  "id": "41827687"
},
{
  "title": "Satish Kumar vs Food Corporation Of India And Others",
  "date": "30 December, 2017",
  "id": "168327475"
},
{
  "title": "State Of Himachal Pradesh vs Ajay Atwal Alias Dikky & Ors",
  "date": "30 December, 2017",
  "id": "101825228"
},
{
  "title": "State Of J&K; And Others. vs S. Bhupinder Singh",
  "date": "30 December, 2017",
  "id": "39078328"
},
{
  "title": "Shankar Dass And Ors vs State And Ors",
  "date": "30 December, 2017",
  "id": "109678039"
},
{
  "title": "State Of J&K; And Others. vs Rajesh Anand",
  "date": "30 December, 2017",
  "id": "115017411"
}

    ],
    allahabad: [
      {
          "title": "Guddu Kumar Gupta vs State Of U.P.",
          "date": "22 December, 2017",
          "id": "141389328"
      },
      {
          "title": "Idrish Alias Khaura vs State Of U.P.",
          "date": "22 December, 2017",
          "id": "101443364"
      },
      {
          "title": "Ram Naresh Singh vs State Of U.P.Thru Prin.Secy.Urban ...",
          "date": "22 December, 2017",
          "id": "199856388"
      },
      {
          "title": "Tilisara Rai Magar vs State Of U.P.",
          "date": "22 December, 2017",
          "id": "50575946"
      },
      {
          "title": "Dr. Ramjeet Singh Yadav vs Bar Council Of Uttar Pradesh And 4 Others",
          "date": "22 December, 2017",
          "id": "128946615"
      },
      {
          "title": "Tuhi Ram vs State Of U.P. And 3 Ors",
          "date": "22 December, 2017",
          "id": "32202197"
      },
      {
          "title": "Yasarth Singh @ Amit Singh @ Aryan Singh vs State Of Up",
          "date": "22 December, 2017",
          "id": "37576886"
      },
      {
          "title": "Mohammad And Others vs State Of U.P.",
          "date": "21 December, 2017",
          "id": "61195605"
      },
      {
          "title": "Rajendra Kumar Malhotra And Another vs Ashok Kumar Jain And 3 Others",
          "date": "21 December, 2017",
          "id": "5801662"
      },
      {
          "title": "Siya Ram vs State Of U.P.",
          "date": "21 December, 2017",
          "id": "21601914"
      },
      {
        "title": "State Of U.P. vs Harveer Yadav S/O Prem Singh @Mooseram",
        "date": "3 April, 2025",
        "id": "171024737"
    },
    {
        "title": "Sundar Singh vs State Of U.P. And Another",
        "date": "3 April, 2025",
        "id": "12292815"
    },
    {
        "title": "Hari Narain Pal vs Addl. District Judge Court No. 4 Special ...",
        "date": "3 April, 2025",
        "id": "89153940"
    },
    {
        "title": "Ram Sevak vs State Of U.P. Thru. Prin. Secy., Revenue ...",
        "date": "3 April, 2025",
        "id": "190329724"
    },
    {
        "title": "Ram Kumar And 13 Others vs Union Of India And 2 Others",
        "date": "3 April, 2025",
        "id": "188102419"
    },
    {
        "title": "Ashok Kumar And 3 Others vs Union Of India And 2 Others",
        "date": "3 April, 2025",
        "id": "162766701"
    },
    {
        "title": "Ram Kishor vs State Of U.P. Thru. Prin. Secy., Revenue ...",
        "date": "3 April, 2025",
        "id": "27264044"
    },
    {
        "title": "Dharmendra vs State Of U.P.",
        "date": "3 April, 2025",
        "id": "74580062"
    },
    {
        "title": "Pintu Chaurasiya Alias Virendra vs State Of U.P.",
        "date": "3 April, 2025",
        "id": "76558951"
    },
    {
        "title": "Pravesh Kumar vs State Of U.P.",
        "date": "3 April, 2025",
        "id": "154636394"
    },        {
      "title": "M/S Shivam Enterprises Sahabganj Thru. ... vs State Of U.P. Thru. Addl. Chief Secy. ...",
      "date": "30 December, 2024",
      "id": "78148798"
  },
  {
      "title": "Devendra Shukla @ Mantu And Anr. vs State Of U.P.",
      "date": "20 December, 2024",
      "id": "99167297"
  },
  {
      "title": "Natthu Singh vs State Of U.P.",
      "date": "20 December, 2024",
      "id": "21276570"
  },
  {
      "title": "Naseem Javed vs The State Of U.P. Thru. Prin. Secy. Home, ...",
      "date": "20 December, 2024",
      "id": "10893200"
  },
  {
      "title": "Rajik And 3 Others vs State Of U.P. And Another",
      "date": "20 December, 2024",
      "id": "18261281"
  },
  {
      "title": "Ritu Devi vs State Of Up And 9 Others",
      "date": "20 December, 2024",
      "id": "93976497"
  },
  {
      "title": "State Of U.P. And Another vs M/S Jindal Concrete Udhyog",
      "date": "20 December, 2024",
      "id": "122957192"
  },
  {
      "title": "In The Matter Of Auto Tractors Ltd vs Manish Goeal Sr. Advocate",
      "date": "20 December, 2024",
      "id": "131848697"
  },
  {
      "title": "Sattar vs State Of U.P.",
      "date": "20 December, 2024",
      "id": "78058895"
  },
  {
      "title": "Rachit Kumar Yadav And 2 Others vs State Of U.P. Thru. Prin. Secy. Home ...",
      "date": "20 December, 2024",
      "id": "113827967"
  },{
    "title": "Ram Sumer vs Sukan",
    "date": "22 December, 2023",
    "id": "13039537"
},
{
    "title": "Eersad Ali And Another vs State Of U.P Thru. Prin. Secy. Home Civil ...",
    "date": "22 December, 2023",
    "id": "82004570"
},
{
    "title": "Shobhana Bhartia And Ors. vs The State Of U.P. Thru. Prin. Secy. Home ...",
    "date": "22 December, 2023",
    "id": "157603440"
},
{
    "title": "Dhanush Vir Singh vs State Of U.P. And Anr.",
    "date": "22 December, 2023",
    "id": "80624121"
},
{
    "title": "Amar Ujala Thru. Authorized ... vs State Of U.P. And Anr.",
    "date": "22 December, 2023",
    "id": "119505337"
},
{
    "title": "Rahisan vs State Of U.P.",
    "date": "22 December, 2023",
    "id": "51522481"
},
{
    "title": "Salauddin And 8 Others vs State Of U.P. And Anr.",
    "date": "22 December, 2023",
    "id": "84075470"
},
{
    "title": "Smt. Shilpa Sharma And Another vs State Of U.P. And Another",
    "date": "22 December, 2023",
    "id": "22765146"
},
{
    "title": "Shyam Shankar Mishra And Another vs State Of U.P. And Another",
    "date": "22 December, 2023",
    "id": "74655293"
},
{
    "title": "Om Prakash @ Jani vs State Of U.P.",
    "date": "22 December, 2023",
    "id": "166422934"
}, {
  "title": "Vaibhav Pandey vs State Of U.P. Thru. Prin. Secy. Deptt. ...",
  "date": "27 December, 2022",
  "id": "46872346"
},
{
  "title": "Praveen Kumar Rai Urf Jhillu Rai vs State Of U.P. And 2 Others",
  "date": "23 December, 2022",
  "id": "70056539"
},
{
  "title": "Jamshed vs State Of U.P.",
  "date": "23 December, 2022",
  "id": "60819778"
},
{
  "title": "Deepak Sharma vs State Of U.P.",
  "date": "23 December, 2022",
  "id": "90140367"
},
{
  "title": "K.K.Singh vs Board Of Revenue",
  "date": "23 December, 2022",
  "id": "23309363"
},
{
  "title": "Birendra Singh And 4 Others vs Board Of Revenue And 9 Others",
  "date": "23 December, 2022",
  "id": "42607535"
},
{
  "title": "Ravi Pratap Singh vs State Of U.P. Thru. Addl. Chief Secy. / ...",
  "date": "23 December, 2022",
  "id": "32220490"
},
{
  "title": "Monu @ Mannu vs State Of U.P. And 3 Others",
  "date": "23 December, 2022",
  "id": "162487182"
},
{
  "title": "Parshuram vs State Of U.P. Thru. Addl.Chief Secy. ...",
  "date": "23 December, 2022",
  "id": "125642399"
},
{
  "title": "Nahida Fatima Alias Naheed Fatma vs State Of U.P. And 2 Others",
  "date": "23 December, 2022",
  "id": "123042170"
},        {
  "title": "Vibhor Rana vs Union Of India",
  "date": "24 December, 2021",
  "id": "159287925"
},
{
  "title": "Renu Chaudhary vs State Of U.P. And 3 Others",
  "date": "24 December, 2021",
  "id": "127360566"
},
{
  "title": "Sanjay Sharma vs State Of U.P.",
  "date": "24 December, 2021",
  "id": "148717004"
},
{
  "title": "Harnath Singh And Others vs State Of U.P.",
  "date": "24 December, 2021",
  "id": "5945935"
},
{
  "title": "Chandan Singh vs State Of U.P.",
  "date": "24 December, 2021",
  "id": "25122024"
},
{
  "title": "Rakesh Kumar Shukla vs State Of U.P. And Another",
  "date": "24 December, 2021",
  "id": "28518348"
},
{
  "title": "Janki Prasad vs Sanjay Kumar And Ors.",
  "date": "24 December, 2021",
  "id": "147269278"
},
{
  "title": "Anil Kumar Puri vs Distt.Cooperative Bank Ltd. Sitapur ...",
  "date": "24 December, 2021",
  "id": "91530985"
},
{
  "title": "Bali Ram Singh vs State Of U.P. And 4 Others",
  "date": "24 December, 2021",
  "id": "131876631"
},
{
  "title": "Anurag Mehrotra vs State Of U.P.Thru Addl.Chief ...",
  "date": "24 December, 2021",
  "id": "173813121"
}  ,      {
            "title": "Vibhor Rana vs Union Of India",
            "date": "24 December, 2021",
            "id": "159287925"
        },
        {
            "title": "Renu Chaudhary vs State Of U.P. And 3 Others",
            "date": "24 December, 2021",
            "id": "127360566"
        },
        {
            "title": "Sanjay Sharma vs State Of U.P.",
            "date": "24 December, 2021",
            "id": "148717004"
        },
        {
            "title": "Harnath Singh And Others vs State Of U.P.",
            "date": "24 December, 2021",
            "id": "5945935"
        },
        {
            "title": "Chandan Singh vs State Of U.P.",
            "date": "24 December, 2021",
            "id": "25122024"
        },
        {
            "title": "Rakesh Kumar Shukla vs State Of U.P. And Another",
            "date": "24 December, 2021",
            "id": "28518348"
        },
        {
            "title": "Janki Prasad vs Sanjay Kumar And Ors.",
            "date": "24 December, 2021",
            "id": "147269278"
        },
        {
            "title": "Anil Kumar Puri vs Distt.Cooperative Bank Ltd. Sitapur ...",
            "date": "24 December, 2021",
            "id": "91530985"
        },
        {
            "title": "Bali Ram Singh vs State Of U.P. And 4 Others",
            "date": "24 December, 2021",
            "id": "131876631"
        },
        {
            "title": "Anurag Mehrotra vs State Of U.P.Thru Addl.Chief ...",
            "date": "24 December, 2021",
            "id": "173813121"
        },        {
          "title": "Indra Narayan vs State Of U.P. And 4 Others",
          "date": "19 December, 2020",
          "id": "36816450"
      },
      {
          "title": "Ram Dhani And Another vs State Of U.P. And 15 Others",
          "date": "18 December, 2020",
          "id": "50828140"
      },
      {
          "title": "Abushad Ahmad vs State Of U.P. And 3 Others",
          "date": "18 December, 2020",
          "id": "108115185"
      },
      {
          "title": "State Of U.P. And 2 Others vs Himanshu Yadav",
          "date": "18 December, 2020",
          "id": "20936949"
      },
      {
          "title": "Ramu vs State Of U.P.",
          "date": "18 December, 2020",
          "id": "58348910"
      },
      {
          "title": "Veer Kunwar Singh vs State Of U.P. And 4 Others",
          "date": "18 December, 2020",
          "id": "8279760"
      },
      {
          "title": "Rajkumar Kapoor vs State Of U.P. And 5 Others",
          "date": "18 December, 2020",
          "id": "105019719"
      },
      {
          "title": "Keshav Singh @ Kesho vs State Of U.P.",
          "date": "18 December, 2020",
          "id": "131856280"
      },
      {
          "title": "Mahendra Singh Baghel Adn Another vs State Of U.P.",
          "date": "18 December, 2020",
          "id": "48359512"
      },
      {
          "title": "Aman Srivastava vs State Of U.P. And Another",
          "date": "18 December, 2020",
          "id": "27973151"
      },{
        "title": "Virendra Kumar Agarwal vs State Of U.P. And Another",
        "date": "22 December, 2018",
        "id": "187323637"
    },
    {
        "title": "Shanker @ Shiv Shanker Verma & Another vs State Of U.P.",
        "date": "21 December, 2018",
        "id": "195681707"
    },
    {
        "title": "Justice (Retd.) Sanjay Misra Lokayukta ... vs State Of U.P. Thru. Prin. Secy. Rajya ...",
        "date": "21 December, 2018",
        "id": "121978194"
    },
    {
        "title": "Manglu vs State Of U.P.",
        "date": "21 December, 2018",
        "id": "41457796"
    },
    {
        "title": "Pramod Yadav vs State Of U.P.",
        "date": "21 December, 2018",
        "id": "13358957"
    },
    {
        "title": "The Indian Hume Pipe Company Ltd. Thru. ... vs State Of U.P. Thru. Prin. Secy. Housing & ...",
        "date": "21 December, 2018",
        "id": "37156630"
    },
    {
        "title": "Feroz Varun Gandhi(M.P.) vs State Of U.P.",
        "date": "21 December, 2018",
        "id": "73230618"
    },
    {
        "title": "Gyan Bharti Madhyamik Vidyalaya Chak ... vs Additional Collector And 4 Others",
        "date": "21 December, 2018",
        "id": "4113532"
    },
    {
        "title": "Ram Chanda Nishad vs State Of U.P.",
        "date": "21 December, 2018",
        "id": "115869475"
    },
    {
        "title": "Basic Shiksha Parishad vs The U.P.P.S.T.Lucknow",
        "date": "21 December, 2018",
        "id": "84500514"
    },{
      "title": "Vikas Gupta And 4 Others vs State Of U.P. And 3 Others",
      "date": "27 December, 2019",
      "id": "178343360"
  },
  {
      "title": "Rajat Gangwar vs State Of U.P. And 4 Others",
      "date": "27 December, 2019",
      "id": "155972493"
  },
  {
      "title": "Smt. Ekta Agarwal And 2 Ors. vs Alindra Chandra And 3 Ors.",
      "date": "25 December, 2019",
      "id": "97350686"
  },
  {
      "title": "State Of U.P. vs Mahabir And Others",
      "date": "22 December, 2019",
      "id": "25978631"
  },
  {
      "title": "Ranvijay Singh And Ors. (Complaint ... vs The State Of U.P And Anr.",
      "date": "20 December, 2019",
      "id": "18737703"
  },
  {
      "title": "Jai Prakash Yadav And Another vs Om Prakash Arya, D.M. And 12 Others",
      "date": "20 December, 2019",
      "id": "154468100"
  },
  {
      "title": "Ramnath vs Vinod Kumar Mishra, S.P. And Another",
      "date": "20 December, 2019",
      "id": "5795138"
  },
  {
      "title": "Aainul Haq And Another vs State Of U.P. And Another",
      "date": "20 December, 2019",
      "id": "175757980"
  },
  {
      "title": "Jai Prakash Pal vs State Of U.P.Thr.Chief Secretary, ...",
      "date": "20 December, 2019",
      "id": "67077842"
  },
  {
      "title": "Radhey Shyam vs Ram Kishun And 4 Others",
      "date": "20 December, 2019",
      "id": "169881689"
  },
    {
        "title": "Guddu Kumar Gupta vs State Of U.P.",
        "date": "22 December, 2017",
        "id": "141389328"
    },
    {
        "title": "Idrish Alias Khaura vs State Of U.P.",
        "date": "22 December, 2017",
        "id": "101443364"
    },
    {
        "title": "Ram Naresh Singh vs State Of U.P.Thru Prin.Secy.Urban ...",
        "date": "22 December, 2017",
        "id": "199856388"
    },
    {
        "title": "Tilisara Rai Magar vs State Of U.P.",
        "date": "22 December, 2017",
        "id": "50575946"
    },
    {
        "title": "Dr. Ramjeet Singh Yadav vs Bar Council Of Uttar Pradesh And 4 Others",
        "date": "22 December, 2017",
        "id": "128946615"
    },
    {
        "title": "Tuhi Ram vs State Of U.P. And 3 Ors",
        "date": "22 December, 2017",
        "id": "32202197"
    },
    {
        "title": "Yasarth Singh @ Amit Singh @ Aryan Singh vs State Of Up",
        "date": "22 December, 2017",
        "id": "37576886"
    },
    {
        "title": "Mohammad And Others vs State Of U.P.",
        "date": "21 December, 2017",
        "id": "61195605"
    },
    {
        "title": "Rajendra Kumar Malhotra And Another vs Ashok Kumar Jain And 3 Others",
        "date": "21 December, 2017",
        "id": "5801662"
    },
    {
        "title": "Siya Ram vs State Of U.P.",
        "date": "21 December, 2017",
        "id": "21601914"
    }
  ]  ,
  kolkata:[
    {
        "title": "Bts Research International Pty Ltd ... vs The Controller General Of Patents & ...",
        "date": "3 April, 2025",
        "id": "52802766"
    },
    {
        "title": "Ranjusree Pal vs Suparna Pal Chowdhury & Ors",
        "date": "3 April, 2025",
        "id": "72490094"
    },
    {
        "title": "Balaram Das vs Guru Charan Biswas",
        "date": "3 April, 2025",
        "id": "108535939"
    },
    {
        "title": "Starlite Vyapaar Pvt. Ltd vs Sng Fashions Pvt. Limited",
        "date": "3 April, 2025",
        "id": "187005716"
    },
    {
        "title": "D.D. Infrastructure Private Limited vs Union Of India & Ors",
        "date": "3 April, 2025",
        "id": "6347170"
    },
    {
        "title": "Calcutta Commercial Bank Ltd. (In Liqn) vs Pradip Nahar",
        "date": "3 April, 2025",
        "id": "9388258"
    },
    {
        "title": "Md. Mofazzular Rahman And Ors vs Md. Sarfaraz Alam And Ors",
        "date": "3 April, 2025",
        "id": "176194503"
    },
    {
        "title": "West Bengal Industrial Development ... vs Tata Motors Limited",
        "date": "3 April, 2025",
        "id": "68238399"
    },
    {
        "title": "Satya Prasanna Khan (Dec) vs Unknown",
        "date": "3 April, 2025",
        "id": "180278791"
    },
    {
        "title": "M/S. Astrex Enterprise Private Limited vs Sunil Kishin Punwani",
        "date": "3 April, 2025",
        "id": "182578867"
    },
    {
      "title": "Badal Kumar Mandal vs The Chairman Indian Museum Board Of ...",
      "date": "30 April, 2024",
      "id": "54318153"
  },
  {
      "title": "Commissioner Of Income Tax-14 vs M/S. Assam Bengal Carriers",
      "date": "30 April, 2024",
      "id": "164913514"
  },
  {
      "title": "Tax vs M/S. Akzo Noble India Limited",
      "date": "30 April, 2024",
      "id": "23745367"
  },
  {
      "title": "Upl Limited vs The Controller Of Patents Designs And ...",
      "date": "30 April, 2024",
      "id": "160685975"
  },
  {
      "title": "Commissioner Of Income Tax vs M/S. Mbl Infrastructure Limited",
      "date": "30 April, 2024",
      "id": "37123827"
  },
  {
      "title": "Kolkata vs M/S. Shree Ganesh Jewellery",
      "date": "30 April, 2024",
      "id": "140613071"
  },
  {
      "title": "Income Tax vs M/S. Shubham Impex (P) Ltd",
      "date": "30 April, 2024",
      "id": "139952487"
  },
  {
      "title": "Hindustan Unilever Limited vs Emami Limited",
      "date": "30 April, 2024",
      "id": "90027136"
  },
  {
      "title": "H P Ispat Private Limited vs Susanta Dey And Anr",
      "date": "30 April, 2024",
      "id": "42877572"
  },
  {
      "title": "Bansal Tea Company vs Hershey India Private Limited & Anr",
      "date": "30 April, 2024",
      "id": "75805944"
  },
  {
    "title": "Prem Kumar Chowdhury vs Jiyajeerao Cotton Mills Limited And Ors",
    "date": "28 March, 2024",
    "id": "106729637"
},
{
    "title": "Sri Binod Bihari Samal vs The Kolkata Municipal Corporation And ...",
    "date": "28 March, 2024",
    "id": "8252866"
},
{
    "title": "State Of West Bengal vs Cab Engineering Private Limited",
    "date": "28 March, 2024",
    "id": "150971119"
},
{
    "title": "Soumo Sekhar Dutta And Ors vs The Kolkata Municipal Corporation And ...",
    "date": "28 March, 2024",
    "id": "162807751"
},
{
    "title": "Gaurav Poddar vs Uco Bank And Ors",
    "date": "28 March, 2024",
    "id": "67275297"
},
{
    "title": "Siddhartha Sen vs State Of West Bengal And Ors",
    "date": "28 March, 2024",
    "id": "69899887"
},
{
    "title": "Pradip Kumar Datta vs Ms. Hashi Mitra & Ors",
    "date": "28 March, 2024",
    "id": "64749175"
},
{
    "title": "Siliguri Jalpaiguri Development ... vs Bengal Unitech Universal Siliguri ...",
    "date": "28 March, 2024",
    "id": "168744289"
},
{
    "title": "Siliguri Jalpaiguri Development ... vs Bengal Unitech Universal Siliguri ...",
    "date": "28 March, 2024",
    "id": "9632301"
},
{
    "title": "Siliguri Jalpaiguri Development ... vs Bengal Unitech Universal Siliguri ...",
    "date": "28 March, 2024",
    "id": "137154298"
},
  ],
amravati:[
  {
      "title": "P V Mudhun Reddy Peddireddi Venkata ... vs The State Of Andhra Pradesh",
      "date": "3 April, 2025",
      "id": "107524831"
  },
  {
      "title": "National Insurance Company Limited vs Duvvuri Yeeswaramma",
      "date": "3 April, 2025",
      "id": "104465370"
  },
  {
      "title": "Shanmukha Kanaka Priya Chinta, vs The State Of Andhra Pradesh",
      "date": "3 April, 2025",
      "id": "59349281"
  },
  {
      "title": "Killampalli Soumya vs The State Of Andhra Pradesh",
      "date": "3 April, 2025",
      "id": "135948303"
  },
  {
      "title": "Smt.K.Uma Bai vs Smt.K.Veeru Bai",
      "date": "3 April, 2025",
      "id": "53598250"
  },
  {
      "title": "R.Ratnaswamy vs State Of Andhra Pradesh,",
      "date": "3 April, 2025",
      "id": "158603028"
  },
  {
      "title": "Jinagam Kanchanamala vs The State Of Ap",
      "date": "3 April, 2025",
      "id": "168245429"
  },
  {
      "title": "Katna Pallapuraju vs The State Of Andhra Pradesh",
      "date": "3 April, 2025",
      "id": "198417550"
  },
  {
      "title": "Thathi Reddy Jyothi Reddy vs The State Of Andhra Pradesh",
      "date": "3 April, 2025",
      "id": "127027108"
  },
  {
      "title": "Velgode Educational Society, Rep.By ... vs The District Registration And ...",
      "date": "3 April, 2025",
      "id": "48116936"
  }
],
 
meghalaya:[
  {
      "title": "Shri. Balbinder Singh vs . State Of Meghalaya & Ors.",
      "date": "3 April, 2025",
      "id": "24629722"
  },
  {
      "title": "Shri Andrew Shabong vs State Of Meghalaya",
      "date": "3 April, 2025",
      "id": "163355909"
  },
  {
      "title": "Reliance General Insurance Co. Ltd. vs . Smti Ranilla Marak & Ors.",
      "date": "3 April, 2025",
      "id": "66823537"
  },
  {
      "title": "Date Of Decision: 03.04.2025 vs Shri Sanjay Kumar Binani",
      "date": "3 April, 2025",
      "id": "197199892"
  },
  {
      "title": "Date Of Decision: 03.04.2025 vs State Of Meghalaya Represented By The ...",
      "date": "3 April, 2025",
      "id": "124854074"
  },
  {
      "title": "1.Shri. Diphrin Nongrum vs . 1.The State Of Meghalaya, Represented",
      "date": "3 April, 2025",
      "id": "80511749"
  },
  {
      "title": "Anup M. Sangma & Ors. vs . State Of Meghalaya & Ors.",
      "date": "3 April, 2025",
      "id": "155661303"
  },
  {
      "title": "Anup M. Sangma & Ors. vs . State Of Meghalaya & Ors.",
      "date": "3 April, 2025",
      "id": "118410118"
  },
  {
      "title": "Anup M. Sangma & Ors. vs . State Of Meghalaya & Ors.",
      "date": "3 April, 2025",
      "id": "8170594"
  },
  {
      "title": "Anup M. Sangma & Ors. vs . State Of Meghalaya & Ors.",
      "date": "3 April, 2025",
      "id": "106068322"
  }
],
telangana: [
  {
      "title": "M/S. Brothers Marketing, vs The Assistant Commissioner St,",
      "date": "3 April, 2025",
      "id": "81200681"
  },
  {
      "title": "Sai Chaitanya Steel Agencies vs The Assistant Commissioner State Tax",
      "date": "3 April, 2025",
      "id": "179320498"
  },
  {
      "title": "Prashanth Furniture vs The Assistant Commissioner State Tax",
      "date": "3 April, 2025",
      "id": "166516896"
  },
  {
      "title": "M/S. Brothers Marketing vs The Assistant Commissioner St",
      "date": "3 April, 2025",
      "id": "79883146"
  },
  {
      "title": "M/S.League Constructions vs The Assistant Commissioner St",
      "date": "3 April, 2025",
      "id": "154140373"
  },
  {
      "title": "Hungry Jacks Bakers And Confectioners vs The Asst. Commissioner St",
      "date": "3 April, 2025",
      "id": "88670342"
  },
  {
      "title": "M/S. Serene Constructions Llp vs The State Of Telangana",
      "date": "3 April, 2025",
      "id": "7370469"
  },
  {
      "title": "M/S. Sri Durga Bhavani Enterprises vs Assistant Commissioner State Tax",
      "date": "3 April, 2025",
      "id": "102253437"
  },
  {
      "title": "M/S. Sri Bhadrakali Medical Stores vs State Tax Officer",
      "date": "3 April, 2025",
      "id": "175755313"
  },
  {
      "title": "S. Narasimhulu And 9 Ors. vs Prl. Secy., Health And 3 Ors.",
      "date": "3 April, 2025",
      "id": "179983230"
  }
],
tripura: [
  {
      "title": "Sri Babul Pal vs The State Of Tripura & 3 Ors",
      "date": "3 April, 2025",
      "id": "189692170"
  },
  {
      "title": "Smti. Sumista Das vs Sri Rajib Namasudra",
      "date": "2 April, 2025",
      "id": "87315016"
  },
  {
      "title": "Mani Kanchan Ghosh vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "149899958"
  },
  {
      "title": "Sri Ram Kumar Bose And 14 Ors vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "38261797"
  },
  {
      "title": "Sri Ram Kumar Bose And 14 Ors vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "117162300"
  },
  {
      "title": "Mani Kanchan Ghosh vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "184118182"
  },
  {
      "title": "Mani Kanchan Ghosh vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "86262529"
  },
  {
      "title": "Mani Kanchan Ghosh vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "27404877"
  },
  {
      "title": "Sri Ram Kumar Bose And 14 Ors vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "74577331"
  },
  {
      "title": "Mani Kanchan Ghosh vs The State Of Tripura And 4 Ors",
      "date": "2 April, 2025",
      "id": "93257739"
  }
],uttaranchal:[
  {
      "title": "3 April vs State Of Uttarakhand & Others",
      "date": "3 April, 2025",
      "id": "115595095"
  },
  {
      "title": "ABA/301/2025",
      "date": "3 April, 2025",
      "id": "47940545"
  },
  {
      "title": "3 April vs State Of Uttarakhand & Others",
      "date": "3 April, 2025",
      "id": "149979343"
  },
  {
      "title": "WPCRL/2026/2021",
      "date": "3 April, 2025",
      "id": "62753777"
  },
  {
      "title": "WPCRL/1976/2021",
      "date": "3 April, 2025",
      "id": "65460623"
  },
  {
      "title": "WPCRL/1324/2023",
      "date": "3 April, 2025",
      "id": "63549077"
  },
  {
      "title": "C482/310/2024",
      "date": "3 April, 2025",
      "id": "146505488"
  },
  {
      "title": "WPCRL/1967/2021",
      "date": "3 April, 2025",
      "id": "82111526"
  },
  {
      "title": "WPCRL/1957/2021",
      "date": "3 April, 2025",
      "id": "31495813"
  },
  {
      "title": "WPCRL/277/2025",
      "date": "3 April, 2025",
      "id": "40649418"
  }
],sikkim:[
  {
      "title": "Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "16390624"
  },
  {
      "title": "Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "149517958"
  },
  {
      "title": "Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "84673900"
  },
  {
      "title": "Shri Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "16644666"
  },
  {
      "title": "Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "162055153"
  },
  {
      "title": "Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "133739378"
  },
  {
      "title": "Shri Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "182325101"
  },
  {
      "title": "Nandu Ram Rai vs State Of Sikkim",
      "date": "2 April, 2025",
      "id": "142211613"
  },
  {
      "title": "The Manager, Hdfc Ergo General ... vs Laxmi Sherpa And Anr",
      "date": "1 April, 2025",
      "id": "91394107"
  },
  {
      "title": "Kipu Lepcha vs Phumtsung Lepcha And Ors",
      "date": "1 April, 2025",
      "id": "183958803"
  }
],

  loading: false,
  error: null,
};

const Judgementstab = ({ tabName, hcState = "meghalaya" }) => {
  const dispatch = useDispatch();
  const { SC } = useSelector((state) => state.judgements);

  useEffect(() => {
    if (tabName === "Supreme Court") {
      dispatch(fetchSupremeCourtJudgements());
    }
  }, [dispatch, tabName]);

  // Logic for HC vs SC
  const judgements = tabName === "High Court" ? HC[hcState] || [] : SC.judgements || [];
  const loading = tabName === "High Court" ? false : SC.loading;
  const error = tabName === "High Court" ? null : SC.error;

  const HC_PDF_URL = "https://indiankanoon.org/doc/";

  return (
    <Box display={"flex"}>
      <VStack w="100%">
        <HStack w="100%" align="center">
          <Text fontSize="lg" fontWeight="bold" color="#C08729">
            {tabName} Judgements
          </Text>
          <Box flex="1" borderBottom="2px solid #C08729" />
        </HStack>

        {loading && <Spinner color="#C08729" size="lg" />}
        {error && <Text color="red.500">Error: {error}</Text>}

        {judgements.slice(0, 6).map((judgement, index) => (
          <HStack
            key={index}
            w="100%"
            spacing={2}
            align="center"
            p={2}
            flexWrap={{ base: "wrap", sm: "nowrap" }}
            borderBottom="1px solid #C08729"
          >
            <Box className="max-w-full">
              <TbPlayerTrackNextFilled />
            </Box>
            <Box className="w-max">
              <Button
                backgroundColor={"#C08729"}
                variant="link"
                color="#fff"
                fontSize="sm"
                p={2}
              >
                {judgement.date}
              </Button>
            </Box>
            <Text
              as="a"
              fontSize="sm"
              color="black"
              fontWeight="md"
              href={judgement.href ? judgement.href : HC_PDF_URL + judgement.id}
              target="_blank"
              rel="noopener noreferrer"
            >
              {judgement.title}
            </Text>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default Judgementstab;
